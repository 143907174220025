dmx.Component('link', {

  attributes: {
    internal: {
      type: Boolean,
      default: false,
    },
  },

  init (node) {
    this._clickHandler = this._clickHandler.bind(this);
    node.addEventListener('click', this._clickHandler);
  },

  destroy () {
    this.$node.removeEventListener('click', this._clickHandler);
  },

  _navigate () {
    let url = this.$node.getAttribute('href') || '';

    if (url.startsWith('#')) {
      location.hash = url;
      return;
    }
    
    if (dmx.routing.router === 'hash') {
      url = '#!' + url;
    }
    
    const title = this.$node.title;
    history.pushState({ title: title || document.title }, '', url);
    if (title) document.title = title;
    window.dispatchEvent(new Event('pushstate'));
  },

  _clickHandler (event) {
    const url = this.$node.getAttribute('href') || '';

    if ((this.props.internal || url.startsWith('#')) && !event.ctrlKey && event.button === 0) {
      event.preventDefault();
      this._navigate();
    }
  },

});
